@import '../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.homepage-section {
    background-color: #f9f9f9;
    padding: {
        bottom: 2.5rem;
        top: 1rem;
    }

    @include media-breakpoint-up(md) {
        $padding: 1.5rem;

        padding: {
            bottom: $padding * 2;
            top: $padding;
        }
    }

    @include media-breakpoint-up(lg) {
        $padding: 2.5rem;

        padding: {
            bottom: $padding * 2;
            top: $padding;
        }
    }
}
