@import '~bootstrap/scss/mixins';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/variables/fonts';
@import '../../../../../common/styles/bootstrap/variables';
@import '../../../../../common/styles/bootstrap/custom';

.slider-wrapper {
    .col {
        padding: 0;
    }

    // ************************************************ active image - main image
    .active-image {
        padding: 0;

        img {
            width: 100%;
            height: 100%;

            border-radius: $border-radius;
        }

        .cover {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            background: linear-gradient(
                180deg,
                rgba($color-black, 0.6) 0%,
                rgba($color-black, 0.6) 0.01%,
                rgba($color-black, 0) 0.02%,
                rgba($color-black, 0.596935) 100%
            );

            .foot {
                $horizontal-position: 30px;

                width: 100%;
                position: absolute;
                bottom: 0;
                padding: 20px $horizontal-position;

                div {
                    @include wrap-text();

                    color: $color-white;
                    float: left;
                    height: 100%;
                    width: 75%;
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }

                a {
                    float: left;
                    width: 25%;
                    text-align: center;

                    @include media-breakpoint-up(lg) {
                        float: right;
                    }
                }
            }
        }
    }

    // ************************************************ END active image - main image

    $margin-active-info: 35px;

    @mixin category-properties() {
        .category {
            @extend %light-letter-spacing;

            font-weight: $font-weight-bold;
            color: $color-grey;
            margin-bottom: 5px;
            font-size: 0.7rem;
            text-transform: uppercase;
        }
    }

    // ************************************************ info about active image - RWD

    $article-info-font-size: 0.9rem;

    .active-image-decoration {
        margin-top: $margin-active-info;
        border-bottom: 2px solid $color-green;
        position: relative;

        span {
            position: absolute;
            top: -1rem;
            left: 20px;
            color: $color-green;
        }
    }

    .active-image-info {
        padding-top: 15px;
        font-size: $article-info-font-size;

        .title {
            @include wrap-text();

            font-weight: $font-weight-between-normal-and-bold;
            margin-bottom: 10px;
            cursor: pointer;
        }

        .date {
            font-size: $article-info-font-size;
        }

        @include category-properties();
    }

    // ************************************************ END info about active image - RWD
    // ************************************************ info about other recommended articles

    .other-articles {
        margin-left: $margin-active-info;

        .other-article {
            position: relative;
            height: 30%;
            width: 100%;
            font-size: $article-info-font-size;

            border-left: 2px solid $color-grey-ultra-light;
            padding-left: 14px;

            &:nth-child(even) {
                margin: 5% 0;
            }

            @include category-properties();

            .title {
                @include wrap-text();

                font-weight: $font-weight-between-normal-and-bold;
                margin-bottom: 10px;
                color: $color-grey;
                cursor: pointer;
                overflow-y: auto;
                height: 70%;
            }

            .date {
                position: absolute;
                bottom: 0;
                font-size: $article-info-font-size;
            }

            &.active {
                border-left: 2px solid $color-green;

                &:before {
                    position: absolute;
                    left: -0.8rem;
                    font-family: $font-triangle-decoration;
                    content: '◄';
                    color: $color-green;
                }

                .title {
                    font-weight: $font-weight-between-normal-and-bold;
                    color: $color-black;
                    margin-bottom: 10px;
                }
            }
        }
    }

    // ************************************************ END info about other recommended articles
}
