@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$breakpoint: lg;

.title,
.subtitle {
    text-align: center;

    @include media-breakpoint-up($breakpoint) {
        text-align: left;
    }
}

.title {
    font-weight: $font-weight-bold;

    @include media-breakpoint-up($breakpoint) {
        font-size: $h2-font-size;
    }
}

.form-wrapper {
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up($breakpoint) {
        margin-left: initial;
        margin-right: initial;
    }

    .newsletter-form {
        margin-top: 1.5rem;

        @include media-breakpoint-up($breakpoint) {
            display: flex;
            flex-direction: row;
        }

        .email-input,
        .subscribe-button {
            font-size: $h56-font-size;
        }

        .fields-container {
            display: flex;
            flex-direction: column;
            width: 68%;
            height: 50px;

            > div {
                align-self: center;
            }
        }

        .email-input {
            $padding: 11px;

            background-color: $color-grey-light-transparent;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 0;
            color: $color-white;
            padding: {
                bottom: $padding;
                top: $padding;
            }
            text-align: center;

            @include media-breakpoint-up($breakpoint) {
                $padding-x: 22px;
                $padding-y: 15px;

                border-bottom-left-radius: $border-radius;
                border-top-right-radius: 0;
                flex: 3 1;
                text-align: left;
                padding: $padding-y $padding-x;
            }

            &::placeholder {
                color: $color-white;
            }

            &:focus {
                background-color: rgba($color-grey-light-transparent, 0.15);
            }
        }

        .subscribe-button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 100%;
            align-self: flex-start;

            @include media-breakpoint-up($breakpoint) {
                border-top-right-radius: $btn-border-radius;
                border-bottom-left-radius: 0;
                height: 50px;
                flex: 1 3;
            }
        }
    }
}
