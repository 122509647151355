@import '../variables/fonts';
@import '../mixins/transition';
@import '../mixins/shadow';
@import 'variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/buttons';
@import '~bootstrap/scss/mixins/border-radius';
@import '~bootstrap/scss/vendor/rfs';

$btn-border-radius-md: 0.25rem;
$btn-line-height-md: 1.4;
$btn-font-size-md: $font-size-sm; // 14px
$btn-padding-y-md: 0.5rem;
$btn-padding-x-md: 0.8rem;

$btn-border-radius-xs: 0.25rem;
$btn-line-height-xs: 1;
$btn-font-size-xs: $font-size-base * 0.6875; // 11px
$btn-padding-y-xs: 0.4rem;
$btn-padding-x-xs: 0.6rem;

$light-letter-spacing: 0.05em;

%light-letter-spacing {
    letter-spacing: $light-letter-spacing;
}

%link-without-underline {
    border-bottom: none;
}

.modal-backdrop {
    background-color: $color-black-transparent;
}

.btn {
    font-size: $font-size-sm;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-base;
    }
}

.btn-primary {
    color: $color-white;
}

.btn-outline-secondary {
    color: $color-black;
}

.btn-md {
    @include button-size(
        $btn-padding-y-md,
        $btn-padding-x-md,
        $btn-font-size-md,
        $btn-line-height-md,
        $btn-border-radius-md
    );
    @extend %light-letter-spacing;

    &.btn-outline-secondary {
        border-color: $color-grey-lighter;
    }
}

.btn-xs {
    @include button-size(
        $btn-padding-y-xs,
        $btn-padding-x-xs,
        $btn-font-size-xs,
        $btn-line-height-xs,
        $btn-border-radius-xs
    );
    @extend %light-letter-spacing;

    &.btn-outline-secondary {
        border-color: $color-grey-lighter;
    }
}

// Use border-bottom as link decoration
a {
    border-bottom: 1px solid $color-green;

    &:hover {
        color: $color-green;
        border-bottom-color: transparent;
    }

    &.btn,
    &.without-underline {
        @extend %link-without-underline;
    }
}

// Do not use link decoration for menu items & card links
.nav-link,
.nav-item,
.navbar-brand,
.card {
    @extend %link-without-underline;

    a {
        @extend %link-without-underline;
    }
}
.navbar {
    padding-top: 0;
    padding-bottom: 0;
    .navbar-brand {
        max-width: 50%;
        img {
            width: 222px;
            @media only screen and (max-width: 390px) {
                width: 170px;
            }
        }
    }
}

.form-control {
    @include media-breakpoint-down(xs) {
        font-size: $h56-font-size;
    }
}

// Add animation for all links, buttons & form's fields on mouse over
a,
.btn,
.form-control {
    @include common-transition();
}

// Mark invalid checkboxes using red border
input {
    &[type='checkbox'] {
        &.is-invalid {
            border-color: $color-red;
        }
    }
}

// Apply shadow under main menu
.navbar {
    &.navbar-light {
        @include common-box-shadow();
    }
}

.breadcrumbs {
    width: 100%;
    height: 34px;
    margin-top: -34px;
    padding: 0;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: #888888;
    a {
        text-decoration: none;
        border-bottom: none;
        &:hover {
            color: black;
        }
        &:last-of-type:hover {
            cursor: default;
            color: #888888;
        }
        &::after {
            content: '\2771';
            padding: 6px;
            color: #aaaaaa;
        }
        &:last-of-type::after {
            padding: 0;
            content: '';
        }
    }
    @media (max-width: 900px) {
        margin-top:10px;
    }
}
