.navbar-toggler {
    $angle1: 405deg;
    $angle2: 315deg;
    $translate: 7px;

    &:hover {
        cursor: pointer;
    }

    .crossbar {
        border-radius: 2px;
        height: 2px;
        margin: 5px 0;
        position: relative;
        width: 22px;
    }

    :nth-child(1) {
        animation: crossbar-2-1-reverse 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    }

    :nth-child(2) {
        animation: crossbar-2-2-reverse 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    }

    :nth-child(3) {
        animation: crossbar-2-3-reverse 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    }

    &.is-opened {
        :nth-child(1) {
            animation: crossbar-2-1 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
        }

        :nth-child(2) {
            animation: crossbar-2-2 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
        }

        :nth-child(3) {
            animation: crossbar-2-3 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
        }
    }

    @keyframes crossbar-2-1-reverse {
        from {
            transform: translateY($translate) rotate($angle1);
        }
        to {
            transform: translateY(0px) rotate(0deg);
        }
    }

    @keyframes crossbar-2-2-reverse {
        from {
            opacity: 0;
            transform: rotate($angle1);
        }
        to {
            opacity: 1;
            transform: rotate(0deg);
        }
    }

    @keyframes crossbar-2-3-reverse {
        from {
            transform: translateY($translate * (-1)) rotate($angle2);
        }
        to {
            transform: translateY(0px) rotate(0deg);
        }
    }

    @keyframes crossbar-2-1 {
        to {
            transform: translateY($translate) rotate($angle1);
        }
    }

    @keyframes crossbar-2-2 {
        0% {
            opacity: 1;
            transform: rotate(0deg);
        }
        100% {
            opacity: 0;
            transform: rotate($angle1);
        }
    }

    @keyframes crossbar-2-3 {
        to {
            transform: translateY($translate * (-1)) rotate($angle2);
        }
    }
}
