@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';
.sorting-container-wrapper {
    width: 125px;
    float: right;
    margin-top: -90px;
    margin-right: -24px;
    @include media-breakpoint-down(md) {
        margin-top: -72px;
    }
    .sorting-wrapper {
        display: flex;
        justify-content: flex-end;
        font-size: $h56-font-size;
        @include media-breakpoint-up(lg) {
        }

        .label {
            float: left;
            padding-top: 0.5rem;
            color: $color-grey-lighter;
        }
        .dropdown-menu {
            margin-left: -66px;
        }
        .dropdown {
            float: right;
            .nav-link {
                cursor: pointer;
            }
        }

        img.sorting-desc {
            transform: rotate(180deg);
        }
    }
}
