@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/variables/fonts';
@import '~bootstrap/scss/mixins/breakpoints';

$width-small: 28rem;
$min-breakpoint: 26rem;

.modal-wrapper {
    &.login-modal {
        @include media-breakpoint-up(sm) {
            width: $width-small;
        }

        .modal-body {
            padding: 2rem 0.25rem;

            .form {
                @include media-breakpoint-up(xs) {
                    padding: 0 1.5rem;
                }

                .forgot {
                    text-align: right;
                }

                .password-group {
                    margin-bottom: 0.25rem;
                }

                button.submit {
                    width: 100%;
                    font-size: $font-size-lg;
                }
            }
        }
    }
}
