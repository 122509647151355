@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/functions/background';
@import '../../../../../../common/styles/variables/colors';

.featured-news-section {
    background-color: $color-white;
    padding-bottom: 0 !important;

    .article-card-wrapper {
        & + .article-card-wrapper {
            margin-top: $grid-gutter-width;
        }

        &:first-of-type {
            .featured-article {
                margin-top: 0;
            }
        }

        $breakpoints: (
            2: sm,
            3: lg,
            4: xl,
        );

        @each $index, $breakpoint in $breakpoints {
            &:nth-child(-n + #{$index}) {
                @include media-breakpoint-up($breakpoint) {
                    margin-top: 0; // No margin for the first elements (2, 3 or 4 - depends on breakpoint)

                    .featured-article {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
