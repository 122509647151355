@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/bootstrap/variables';

.panel-side-navbar {
    background-color: $color-white;
    border-radius: $border-radius;
    padding: 10px 0;
    min-height: 250px;
    margin: 10px;

    .nav-item {
        cursor: pointer;

        &.active,
        &:hover {
            background-color: $color-grey-ultra-light;
        }

        .nav-link {
            &.active {
                background-color: $color-grey-ultra-light;
            }
        }
    }
}
