// New colors
//
$color-white: #fff;
$color-black: #000;

$color-grey: #9aa2a4;
$color-grey-darker: #575757;
$color-grey-dark: #a5a5b0;
$color-grey-light: #cccccc;
$color-grey-lighter: #e1e1e1;
$color-grey-ultra-light: #eeeeee;
$color-grey-almost-white: #f9f9f9;
$color-grey-transparent: rgba(#000, 0.15);

$color-green: #71b893;
$color-green-dark: #365846;

$color-red: #f00;
$color-red-dark: #db4437;

$color-google: #db4437;
$color-facebook: #3b5998;
$color-twitter: #00aced;

// ***************************** Calculated colors

$color-black-transparent: rgba($color-black, 0.85);
$color-black-very-transparent: rgba($color-black, 0.08);

$color-grey-light-transparent: rgba($color-black, 0.1);
$color-grey-more-light-transparent: rgba($color-black, 0.3);
$color-grey-super-light-transparent: rgba($color-black, 0.05);

$color-green-light-transparent: rgba($color-green, 0.2);

// Old/Previous colors (all of them should be removed - to be continued...)
//
$old-color-grey-dark: #3a3a3a;
$old-color-grey-dark-lighter: #464c57; // e.g. background of price in Featured Properties (homepage)
$old-color-grey-dark-transparent: rgba($old-color-grey-dark, 0.5);
