@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../common/styles/functions/background';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/variables/fonts';

$breakpoint-horizontal-form: md;

.top-section {
    $padding: 41.35px;
    background-image: url('../images/top-bg.jpg');
    background: {
        position-x: center;
        position-y: bottom;
        size: cover;
    }
    padding: {
        bottom: $padding;
        top: $padding;
    }

    @include media-breakpoint-up($breakpoint-horizontal-form) {
        $ratio: 1.35;

        padding: {
            bottom: $padding * $ratio;
            top: $padding * $ratio;
        }
    }

    @include media-breakpoint-up(xl) {
        $ratio: 2.5;

        padding: {
            bottom: $padding * $ratio;
            top: $padding * $ratio;
        }
    }

    .title,
    .subtitle {
        .text {
            text-align: center;
        }
    }

    .title {
        .text {
            font-size: 48px;
            font-weight: 700;
            line-height: 1.2;

            @include media-breakpoint-up($breakpoint-horizontal-form) {
                font-size: $font-size-xxl;
            }
        }
    }

    .subtitle {
        .text {
            font-size: $font-size-lg;
            font-weight: 400;
            line-height: 1.4;

            @include media-breakpoint-up($breakpoint-horizontal-form) {
                font-size: $h3-font-size;
            }
        }
    }
}
