@import '../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.page-wrapper {
    // Margin between content and the main menu (by applying margin to the main container)
    $padding-bottom: 40px;
    $padding-top: 36px;

    padding: {
        bottom: $padding-bottom;
        top: $padding-top;
    }

    @include media-breakpoint-up(md) {
        padding: {
            bottom: $padding-bottom * 2;
            top: $padding-top * 2;
        }
    }
}
