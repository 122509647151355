@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/fonts';
@import '~bootstrap/scss/mixins/breakpoints';

$width-small: 28rem;

.modal-wrapper.forgot-password-modal {
    @include media-breakpoint-up(sm) {
        width: $width-small;
    }

    .modal-body {
        padding: 1.5rem;
    }
}
