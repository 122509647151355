@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg';
@import '../../../../../../common/styles/variables/colors';

.react-draft-wysiwyg-editor {
    $padding: 15px;

    border: 1px solid $color-grey-ultra-light;
    min-height: 200px;
    padding: {
        left: $padding;
        right: $padding;
    }
}
