@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../common/styles/bootstrap/variables';

$breakpoint: lg;

.carousel {
    align-items: center;
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
    }

    @include media-breakpoint-up($breakpoint) {
        display: none;
    }
}
