@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/variables/fonts';
@import '~bootstrap/scss/mixins/breakpoints';

$width-small: 1260px;

.modal-wrapper {
    &.checkout-modal {
        @include media-breakpoint-up(sm) {
            width: $width-small;
            max-width: calc(100% - 80px);
        }

        .text-lg {
            font-size: $h45-font-size;
        }

        .overflow-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .thumbnail-and-finance {
            @include media-breakpoint-down(sm) {
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .processing-fee-container {
                border: 1px dashed $color-grey-light;
                margin-top: 10px;
                padding: 1rem 0;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                .price {
                    color: $color-green;
                    font-size: 3rem;
                    font-weight: $font-weight-boldest;
                }

                .label {
                    color: $color-green;
                }

                .subtitle {
                    color: $color-grey-dark;
                    font-size: 11px;
                    margin-top: 5px;
                    text-transform: uppercase;
                }
            }
        }

        .prop-list {
            font-size: $h56-font-size;

            .row {
                border-bottom: 1px dashed $color-grey-light;
                padding: 12px 0;

                &:first-of-type {
                    border-top: 1px dashed $color-grey-light;
                }
            }
        }
    }
}
