@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../common/styles/mixins/shadow';
@import '../../../../common/styles/bootstrap/custom';

$breakpoint: lg;

.information {
    .table {
        @include common-box-shadow();

        tbody {
            tr {
                td {
                    display: flex;
                    font-size: $h56-font-size;
                    line-height: 1.3;

                    &:first-of-type {
                        padding-bottom: 0;

                        @include media-breakpoint-up($breakpoint) {
                            padding: $table-cell-padding;
                        }
                    }

                    &:last-of-type {
                        @include wrap-text();

                        font-weight: $font-weight-bold;
                        padding-top: 0;

                        @include media-breakpoint-up($breakpoint) {
                            font-weight: $font-weight-between-normal-and-bold;
                            padding: $table-cell-padding;
                        }
                    }

                    @include media-breakpoint-up($breakpoint) {
                        display: table-cell;
                    }

                    &.related-resources {
                        .list {
                            @include reset-all-margins();
                            list-style: none;

                            .resource {
                                line-height: 2;

                                @include media-breakpoint-up($breakpoint) {
                                    line-height: 2.25;
                                }

                                i {
                                    color: $color-grey-light;
                                }

                                a {
                                    font-weight: normal;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
