@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/bootstrap/variables';

.items {
    margin-top: 0.75rem;

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: $border-radius;
        background-color: $color-green-light-transparent;
        margin-bottom: 0.75rem;

        .item-title {
            font-size: 11px;
        }

        .item-no {
            margin: 0 1rem;

            img {
                $size: 2rem;
                padding-top: 0.25rem;
                width: $size;
                height: $size;
            }
        }

        .remove-btn {
            margin: 0.5rem 0.75rem 0 auto;
            align-self: self-start;
            cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
