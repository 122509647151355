@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';

$breakpoint: md;

.property {
    .left {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up($breakpoint) {
            display: block;
        }

        .top {
            order: 1;
        }

        .gallery {
            order: 2;
        }

        .buy-now-box-mobile {
            order: 3;

            @include media-breakpoint-up($breakpoint) {
                display: none;
            }
        }

        .contact-box-mobile {
            order: 4;

            @include media-breakpoint-up($breakpoint) {
                display: none;
            }
        }

        .description {
            order: 5;

            @include media-breakpoint-up($breakpoint) {
                order: 4;
            }
        }

        .categories {
            order: 6;
        }

        .information-table {
            order: 7;
        }

        .map {
            order: 8;
        }
    }

    .right {
        display: none;

        @include media-breakpoint-up($breakpoint) {
            display: block;
        }

        .buy-now-box {
        }

        .contact-box {
        }
    }
}
