@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';

.column {
    $padding-default: 1.5rem;
    padding-top: $padding-default;
    &:first-of-type {
        padding-top: 0.5rem;
/*        width: 228px; */
        @include media-breakpoint-up(md) {
            padding-top: $padding-default;
        }
    }
    &:nth-of-type(2) {
/*        min-width: 360px; */
        @media only screen and (max-width: 1200px) {
            /* min-width: 265px; */
            /* margin-left: -6px; */
        }
    }
    &:nth-of-type(3) {
/*        max-width: 208px; */
    }

    &:last-of-type {
/*        max-width: 274px !important; */
    }
    @include media-breakpoint-up(lg) {
        display: block;
    }
    @media only screen and (max-width: 640px) {
        /* min-width: 70% !important; */
        /* margin-left: 3% !important; */
    }

    .title {
        font-weight: $font-weight-bold;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
    }
}
