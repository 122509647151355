@import '../../../../../../common/styles/variables/colors';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/variables/fonts';
@import '../../../../../../common/styles/mixins/shadow';
@import '~bootstrap/scss/mixins/breakpoints';

.land-deal-box-wrapper {
    $breakpoint: lg;

    &:first-of-type {
        margin-top: $grid-gutter-width * 0.25;

        @include media-breakpoint-up($breakpoint) {
            margin-top: 0;
        }
    }

    & + .land-deal-box-wrapper {
        margin-top: $grid-gutter-width;

        @include media-breakpoint-up($breakpoint) {
            margin-top: 0;
        }
    }

    @include media-breakpoint-up($breakpoint) {
        display: flex;
        flex-direction: row;
    }

    .land-deal-box {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up($breakpoint) {
            margin-top: $grid-gutter-width * 0.5;
        }

        .icon {
            @include common-box-shadow();
            $size: 52px;

            align-items: center;
            background-color: $color-white;
            border-radius: 50%;
            color: $color-green;
            display: flex;
            height: $size;
            justify-content: center;
            width: $size;

            @include media-breakpoint-up($breakpoint) {
                $newSize: $size + 20;

                height: $newSize;
                width: $newSize;
            }

            i {
                color: $color-green;
                font-size: 1.6rem;

                @include media-breakpoint-up($breakpoint) {
                    font-size: 2rem;
                }
            }
        }

        .title {
            $padding: 0.75rem;

            font-size: $font-size-lg;
            font-weight: $font-weight-between-normal-and-bold;
            padding: {
                bottom: $padding;
                top: $padding;
            }

            @include media-breakpoint-up($breakpoint) {
                $padding: 1.3rem;

                font-size: $h4-font-size;
                padding: {
                    bottom: $padding;
                    top: $padding;
                }
            }
        }

        .title,
        .description {
            text-align: center;
        }
    }
}
