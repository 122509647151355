@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/hover';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/bootstrap/custom';
@import '../../../common/styles/variables/colors';
@import '../../../common/styles/variables/fonts';
@import '../../../common/styles/mixins/shadow';

.article-card-wrapper {
    display: flex;
    justify-content: center;

    & + .article-card-wrapper {
        margin-top: $grid-gutter-width;
    }

    $breakpoints: (
        2: sm,
        3: lg,
    );

    @each $index, $breakpoint in $breakpoints {
        &:nth-child(-n + #{$index}) {
            @include media-breakpoint-up($breakpoint) {
                margin-top: 0; // No margin for the first elements (2 or 3 - depends on breakpoint)
            }
        }
    }

    .article-card {
        @include common-box-shadow();
        $margin: 40px;

        border: 0;
        border-radius: $border-radius;

        .icon {
            color: $color-grey-light;
            margin-right: 0.25rem;
        }

        .card-img-top {
            border-radius: $border-radius $border-radius 0 0;
        }

        .card-body {
            overflow: hidden;

            .before-title {
                @extend %light-letter-spacing;
                font-weight: $font-weight-bold;

                a {
                    color: $color-grey;
                    font-size: $font-size-xs;
                    text-transform: uppercase;
                }
            }

            .card-title {
                margin-bottom: 0;
                margin-top: 0.5rem;

                a {
                    font-weight: $font-weight-boldest;
                    color: $color-green;
                }
            }
        }

        .card-footer {
            margin-top: $card-spacer-x;

            a {
                $icon-margin: 7px;

                align-items: center;
                color: $color-black;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .read-more {
                    .text {
                        font-weight: $font-weight-between-normal-and-bold;

                        @include media-breakpoint-only(sm) {
                            display: none;
                        }
                    }

                    .icon {
                        color: $color-grey-lighter;
                        font-size: $font-size-sm;
                        margin-left: $icon-margin;
                    }
                }
            }
        }
    }
}
