@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/bootstrap/variables';

.layout-container {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;

    .content-wrapper {
        margin: 10px;
        padding: 20px;
        background-color: $color-white;
        border-radius: $border-radius;

        @include media-breakpoint-up(lg) {
            margin: 10px 0;
        }
    }
}
