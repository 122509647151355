@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

@keyframes show-notification {
    0% {
        opacity: 0;
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes hide-notification {
    0% {
        opacity: 1;
        transform: translateX(0);
        display: block;
    }

    100% {
        opacity: 0;
        transform: translateX(1rem);
        display: none;
    }
}

$animation-duration: 1s;

.notification-wrapper {
    .alert {
        margin: 0.5rem 1rem;

        animation-name: show-notification;
        animation-duration: $animation-duration;

        i {
            margin-right: 0.5rem;
        }

        &.hide {
            animation-name: hide-notification;
            animation-fill-mode: both;
            animation-duration: $animation-duration;
        }

        ul {
            margin-bottom: 0;
        }
    }
}
