.acre-spinner {
    $size: 2rem;

    height: $size;
    width: $size;

    &.sm {
        $size: 1.2rem;

        height: $size;
        width: $size;
    }

    &.xs {
        $size: 0.75rem;

        height: $size;
        width: $size;
    }

    &.wrapped {
        $margin: 4rem;

        margin: {
            bottom: $margin;
            top: $margin;
        }
    }
}
