@import '../../../../../../common/styles/variables/colors';
@import '../../../../../../common/styles/bootstrap/variables';

.article-form {
    .dropzone {
        section {
            $margin: $grid-gutter-width * 0.5;

            .drop-area {
                align-items: center;
                border: 1px dashed $color-green;
                border-radius: $border-radius;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                padding: $margin;
                margin-bottom: 0;
                text-align: center;

                .requirements {
                    margin-top: 10px;

                    .title {
                        text-align: left;
                        width: 100%;
                    }

                    .list {
                        list-style: circle;
                        margin-bottom: 0;
                        padding-left: 20px;
                        text-align: left;
                    }
                }
            }

            .files-list {
                margin-top: 1rem;

                .list-item {
                    .icons {
                        padding-top: 0.2rem;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        border-color: $color-grey-light;
                        border-style: solid;
                        border-width: 0 1px 1px 1px;
                        border-radius: 0 0 0.2rem 0.2rem;

                        i {
                            margin: 0 0.1rem;
                        }

                        .delete-icon {
                            color: $color-red-dark;
                            cursor: pointer;
                            font-size: $h4-font-size;
                        }

                        .arrow-icon {
                            color: $color-green;
                            cursor: pointer;
                            font-size: $h4-font-size;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .switch {
        label {
            cursor: pointer;
        }
    }
}
