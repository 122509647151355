@import '../../../../../common/styles/bootstrap/variables';
@import '../../../../../common/styles/variables/fonts';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/hover';

.mobile-buttons {
    display: flex;
    flex-direction: row;
    .user {
        $padding-x: 0.85rem;
        $padding-y: 0.5rem;

        padding: {
            left: $padding-x;
            right: $padding-x;
            top: $padding-y;
            bottom: $padding-y;
        }
        @media only screen and (max-width: 390px) {
            border: none !important;
        }
    }

    .menu-toggler {
        $padding-x: 0.65rem;
        $padding-y: 0.5rem;

        padding: {
            left: $padding-x;
            right: $padding-x;
            top: $padding-y;
            bottom: $padding-y;
        }
        @media only screen and (max-width: 390px) {
            border: none !important;
        }
    }

    .user {
        border-color: $navbar-light-toggler-border-color;
        color: $navbar-light-color;
        font-size: $h45-font-size;
        margin-right: 0.5rem;

        // It's required to fix missing border-bottom, because for all a.btn elements it is disabled globally
        // (in src/common/styles/bootstrap/_custom.scss)
        border-bottom: $btn-border-width solid $navbar-light-toggler-border-color;

        @include hover() {
            background-color: transparent;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
