@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/mixins/shadow';

@mixin unusualLink {
    border-bottom: 0;
}

%fix-long-text {
    @include media-breakpoint-down(md) {
        @include wrap-text();
    }

    @include media-breakpoint-up(md) {
        @include truncate-long-text(block);
    }
}

.property {
    @include common-box-shadow();
    position: relative;
    background-color: $color-white;
    margin-top: 6px;
    border-radius: $border-radius-lg;
    max-width: 350px;
    @media (max-width: 1200px) {
        max-width: calc(50% - 14px);
    }
    @media (max-width: 767px) {
        max-width: fit-content;
    }
    height: auto;
    margin-bottom: 28px;

    a {
        border-bottom: none;
    }
    .preview {
        width: 100%;
        img {
            height: 100%;
            width: 100%;
            border-radius: $border-radius-lg $border-radius-lg 0 0;
            margin-right: 25px;
        }
    }

    .data {
        $padding: 20px;
        .favourite-button {
            line-height: 1.2rem;
            position: absolute;
            right: 6px;
            top: 10px;
            border: 0;
            color: rgba(255, 255, 255, 0.65);
            background-color: transparent;
            transition: all 0.5s ease;
            text-shadow: 2px 2px 6px #000000;
            user-select: none;
            &:hover {color: $color-green; transform: scale(1.3);}
        }

        .name {
            /* @extend %fix-long-text; */
            color: $color-black;
            font-size: $h5-font-size;
            font-weight: $font-weight-bold;
            margin: 10px 20px;
            /* height: 72px; */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .price {
            margin-left: 20px;
            margin-right: 20px;
            color: $color-green;
            font-weight: $font-weight-bold;
            font-size: $h3-font-size;
        }
        .est_price {
            margin-left: 20px;
            margin-right: 20px;
            color: $color-green;
            font-weight: $font-weight-bold;
            font-size: $h5-font-size;
            margin-top:0;
        }

        .address {
            @extend %fix-long-text;
            margin: 10px 20px;
            font-weight: $font-weight-bold;
            font-size: $h5-font-size;
            padding-right: $padding;
            i {
                color: $color-grey-light;
                margin-right: 5px;
            }
        }

        .meta-data {
            border-top: 1px solid $color-grey-lighter;
            display: flex;
            justify-content: space-between;
            .size {
                margin: 10px 20px;
                font-weight: $font-weight-between-normal-and-bold;
                font-size: $h56-font-size;
                padding-left: $padding;

                @include media-breakpoint-up(md) {
                    padding-left: 0;
                }

                i {
                    color: $color-grey-light;
                    margin-right: 5px;
                }
            }

            .arrow {
                margin: 10px 20px;
                color: $color-green;
                cursor: pointer;
            }
        }
    }
}
