@import '../../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.navbar {
    .container {
        @include media-breakpoint-between(sm, md) {
            max-width: 100%;
            width: 100%;
        }

        .navbar-collapse {
            &::before {
                background-color: rgba($color-grey-lighter, 0.5);
                content: '';
                height: 2px;
                margin-left: -1rem;
                margin-top: $navbar-padding-y;
                position: absolute;
                width: 100%;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
            @include media-breakpoint-down(sm) {
                height: 260px;
            }
        }
    }
}

.navbar-light {
    background-color: #fff;
    .navbar-toggler {
        .crossbar {
            background-color: $navbar-light-color;
        }
    }
}
.search {
    flex-direction: row;
    flex-grow: 1;
    margin-top: 2rem;
    max-width: 250px;
    padding: 0;
    .search-box {
        .form-group {
            display: inline-flex;
            margin-bottom: 0;
            .input {
                width: 172px;
                .filterable-select__control {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-color: transparent;
                    height: 56px;
                    .filterable-select__placeholder {
                        color: #A5A5B0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .filterable-select__indicator-separator {
                        display: none;
                    }
                }
                .filterable-select__control--menu-is-open {
                    .filterable-select__indicator {
                        transform: rotate(180deg);
                    }
                }
            }
            .button {
                .btn  {
                    height: 56px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    padding: 6px 22px;
                }
            }

        }
    }
}