@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/mixins/shadow';
#root { background-color: #ffffff; }
#root.lands-for-sale { background-color: #e5e5e5; }
.properties-wrapper {
    .customization-bar-wrapper {
        @include common-box-shadow();

        background-color: $color-white;
        padding: 20px;
        border-radius: $border-radius-lg;
        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    .search-box-wrapper {
        background-color: $color-white;
        padding: 20px;
        border-radius: $border-radius-lg;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .sorting-box-wrapper {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
