@import '../../../../common/styles/bootstrap/variables';

.carousel-navigation {
    display: flex;
    justify-content: center;
    padding-top: $grid-gutter-width;
    width: 100%;

    .button {
        // It's required to fix missing border-bottom, because for all a.btn elements it is disabled globally
        // (in src/common/styles/bootstrap/_custom.scss)
        border-bottom: $btn-border-width solid $navbar-light-toggler-border-color;

        cursor: pointer;
        display: flex;
        padding: 0.6rem 0.9rem;

        & + .button {
            margin-left: 0.75rem;
        }

        i {
            font-size: $font-size-lg;
        }
    }
}
