@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/mixins/shadow';
.filters-container {
    position: relative;
    .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: space-between;
        column-gap: 20px;
        margin-top: 24px;
        @media screen and (max-width: 1000px) {
            column-gap: 10px;
        }
        @media screen and (max-width: 767px) {
            display: block;
        }
        .dropdown {
            border: solid 1px rgb(238, 238, 238);
            width: 198px;
            flex-grow: 0;
            @media screen and (max-width: 1200px) {
                width: auto;
            }
            @media screen and (max-width: 767px) {
                display: block;
            }
            a {
                cursor: pointer;
                span {
                    display: inline-block;
                    min-width: 19px;
                    height: 19px;
                    line-height: 17px;
                    font-size: smaller;
                    margin: 0 -3px 0 4px;
                    border-radius: 12px;
                    border: 1px solid #71b893;
                    text-align: center;
                    color: white;
                    background-color: #71b893;
                }
                span.hidden {
                    border-color: transparent;
                    color: transparent;
                    background-color: transparent;
                }
            }
            img {
                margin-top: 7px;
                transform: rotate(180deg);
                float: right;
            }
            .dropdown-menu {
                box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.2);
                min-width: 198px;
                margin-top: -1px;
                margin-left: -1px;
                .content {
                    margin: 16px;
                    .filter-checkboxes {
                        .element {
                            width: max-content;
                        }
                    }
                }
            }
        }
        .dropdown.show {
            img { transform: none; }
        }
        .filterSize {
            flex-grow: 0;
            max-height: 40px;
            line-height: 18px;
            border: solid 1px #eeeeee;
        }
        .filterSize.btn-primary {
            max-width: 100px;
            padding: 11px 18px;
            @media screen and (max-width: 992px) {
                order: -1;
                max-width: 100%;
                width: 100%;
                margin-bottom: 24px;
            }
        }
        .filterSize.btn-white {
            flex-grow: 0;
            text-transform: none;
            font-weight: normal;
            svg {margin-left: 6px;}
            @media screen and (max-width: 767px) {
                display: block;
                margin-top:24px;
                margin-bottom:24px;
                width: 100%;
            }
        }
        .grow {
            flex-grow: 1;
        }
        div.grow {
            flex-grow: 1;
            @media screen and (max-width: 1000px) {
                display: none;
            }

        }
        .spinner-border {
            margin-top: -10px;
        }
    }
    .filters.hidden {
        display: none;
    }
    .filters.visible {
        left: -24px;
        top: 40px;
        padding: 24px;
        padding-left: 23px;
        min-width: 640px;
        width: auto;
        display: flex;
        position: absolute;
        background-color: #ffffff;
        z-index: 200;
        box-shadow: 0px 10px 9px 0px rgba(0,0,0,0.2);
        border: 1px solid #e4e4e4;
        border-radius: 0.25rem;
        .tick {
            position: absolute;
            display: block;
            width: 24px;
            height: 24px;
            background-color: #ffffff;
            border-top: 1px solid #e4e4e4;
            border-left: 1px solid #e4e4e4;
            transform: rotate(45deg);
            right: 74px;
            top: -13px;
            @media screen and (max-width: 992px) {
                display: none;
            }
        }
        @media screen and (max-width: 992px) {
            top: 82px;
            width: auto;
            right: -24px;
            border: none;
        }
        @media screen and (max-width: 767px) {
            position: inherit;
            top: inherit;
            display: inherit;
            margin: 0;
            box-shadow: none;
            padding: 0;
            min-width: 100%;
            margin-left: 24px;
            margin-right: 24px;
            z-index: inherit;
            .dropdown {
                width: 100%;
            }
        }
    }
}



.filter-box {
    padding: 15px 20px;
    border-bottom: 1px solid $color-grey-ultra-light;
    .title {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin-bottom: 16px;

        .arrow-icon {
            float: right;
            cursor: pointer;

            &.close {
                transform: rotate(180deg);
            }
        }
    }

    .content {
        &.close {
            display: none;
        }
    }
}
