@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/hover';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/bootstrap/custom';
@import '../../../../../../common/styles/variables/colors';
@import '../../../../../../common/styles/variables/fonts';
@import '../../../../../../common/styles/mixins/shadow';

.featured-property {
    @include common-box-shadow();
    $margin: 40px;

    border: 0;
    border-radius: $border-radius;
    margin-top: $margin * 0.375;

    a {
        line-height: 1;
    }

    .icon {
        color: $color-grey-light;
        margin-right: 0.25rem;
    }

    .card-img-top {
        border-radius: $border-radius $border-radius 0 0;
    }

    .card-body {
        .before-title,
        .location {
            font-weight: $font-weight-bold;
        }

        .before-title {
            margin-bottom: 4px;
            height: 72px;
            overflow: hidden;
            a {
                /* @include truncate-long-text(block); */
                /* @extend %light-letter-spacing; */
                color: #000;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .card-title {
            margin-bottom: 0;
            margin-top: 0.35rem;
            a {
                color: $color-green;
                font-size: $h23-font-size;
                font-weight: $font-weight-boldest;
            }
            .est_price {
                margin-top: 6px;
                margin-bottom: 6px;
                font-weight: 600;
                font-size: 1rem;
            }
        }

        .location {
            a {
                @include truncate-long-text(block);
                margin-top: 12px;
                color: $color-black;
                font-size: $h6-font-size;
            }
        }
    }

    .card-footer {
        margin-top: 6px;

        a {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .land-size {
                align-items: center;
                display: flex;
                color: $color-black;
                font-size: $font-size-sm;
                font-weight: $font-weight-between-normal-and-bold;

                @include media-breakpoint-up(md) {
                    font-size: $h56-font-size;
                }

                .icon {
                    font-size: $font-size-lg;
                    margin-right: 7px;
                }
            }

            .icon-arrow {
                color: $color-green;
            }
        }
    }
}
