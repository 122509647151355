@import '../variables/colors';
@import 'shadow';

@mixin fancy-checkboxes {
    input[type='checkbox'] {
        appearance: none;
        background-color: $color-white;
        width: 1rem;
        height: 1rem;
        border-radius: 2px;
        border: 1px solid $color-grey-light;
        box-shadow: 0 3px 10px $color-black-very-transparent;

        &:checked {
            &:after {
                content: '\2713';
                font-size: 1.5rem;
                position: absolute;
                top: -15px;
                color: $color-grey;
            }
        }
    }
}

@mixin systemic-checkboxes {
    input[type='checkbox'] {
        background-color: $color-white;
        position: relative;
        margin-right: 0.8rem;
        border: 1px solid $color-grey-lighter;

        &:checked {
            background-color: $color-green;
            border: none;

            @include common-box-shadow();

            &:after {
                color: $color-white;
                position: absolute;
                content: '';
                left: 5px;
                top: 2px;
                width: 6px;
                height: 10px;
                border: solid $color-white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
}
