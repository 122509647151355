.title {
    display: flex;
    justify-content: space-between;

    .actions {
        align-items: center;
        display: flex;

        .action {
            & + .action {
                margin-left: 10px;
            }
        }
    }
}
