@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/bootstrap/custom';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/variables/colors';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';

$smaller-breakpoint: 30rem;

.modal-wrapper {
    padding: 0;
    margin: 2rem auto;
    box-shadow: 0 0 6px $color-grey-transparent;
    width: 80%;
    max-width: none;

    .modal-content {
        border-radius: 0.25rem;
        border: 0;
    }

    .modal-header {
        text-align: center !important;
        display: inline;
        padding: 0;
        border: 0;

        .modal-title {
            $margin-top-bottom: 1.25rem;

            h1 {
                margin: $margin-top-bottom 0;
            }

            .subtitle {
                @include wrap-text();
                margin: $margin-top-bottom 0;
                padding: 0 1rem;
            }
        }

        .my-close {
            cursor: pointer;
            position: absolute;
            top: -20px;
            right: -20px;
            opacity: 1;
            width: 40px;
            height: 40px;
            overflow: hidden;
            background-color: $color-green;
            border-radius: 0.25rem;

            .x {
                position: relative;
                top: 12px;
                left: 12px;
                width: 16px;
                height: 16px;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    margin-top: -1px;
                    background: $color-white;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .modal-body {
        background-color: $color-grey-almost-white;
        text-align: center;
        padding: 0;

        .more {
            margin: 2.5rem 0 1rem;
            font-size: 1rem;
            font-weight: $font-weight-light;
        }

        button.submit {
            @extend %light-letter-spacing;

            width: 100%;
            font-size: 1rem;
            margin-top: 1.25rem;
            font-weight: $font-weight-between-normal-and-bold;
        }

        .socials {
            .social {
                @extend %light-letter-spacing;

                padding: 0;
                color: $color-white;
                overflow: hidden;
                border-radius: 0.25rem;
                font-weight: $font-weight-between-normal-and-bold;
                background-color: transparent;
                border: 0;
                font-size: 1.25rem;

                &:nth-child(1) {
                    margin-right: 0.1rem;
                }

                i {
                    padding: 0.8rem;
                    border-radius: 0.25rem 0 0 0.25rem;
                    width: 6rem;
                    background-color: rgba($color-grey-transparent, 0.1);
                }

                span {
                    padding: 0.8rem;
                    display: none;
                }

                @media (min-width: $smaller-breakpoint) {
                    & {
                        font-size: 1rem;

                        &:nth-child(1) {
                            margin-right: 0.75rem;
                        }
                    }

                    i {
                        padding: 0.8rem;
                        width: auto;
                    }

                    span {
                        padding: 0.8rem;
                        display: inline;
                    }
                }

                &.google {
                    background-color: $color-google;
                }

                &.facebook {
                    background-color: $color-facebook;
                }
            }
        }
    }
}
