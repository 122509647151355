@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/variables/colors';

.title {
    $margin: 1rem;

    display: flex;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    margin: {
        bottom: $margin;
        top: $margin;
    }

    @include media-breakpoint-up(md) {
        $margin: 2rem;

        margin: {
            bottom: $margin;
            top: $margin;
        }
    }
}

.align-center {
    justify-content: center;
}

.align-left {
    justify-content: left;
}

.align-right {
    justify-content: right;
}

.without-margin-top {
    margin-top: 0;
}

.without-margin-bottom {
    margin-bottom: 0;
}

.underline {
    border-bottom: 1px solid $color-green;
    padding-bottom: 5px;
}

h1 {
    &.title {
        @include media-breakpoint-down(md) {
            font-size: $h3-font-size;
        }
    }
}

h2 {
    &.title {
        @include media-breakpoint-down(sm) {
            font-size: $h4-font-size;
        }
    }
}

h3 {
    &.title {
        @include media-breakpoint-down(sm) {
            font-size: $h5-font-size;
        }
    }
}

h4 {
    &.title {
        &.underline {
            padding-bottom: 3px;
        }
    }
}

h5 {
    &.title {
        &.underline {
            padding-bottom: 2px;
        }
    }
}
