.list-wrapper {
    .pagination {
        margin-top: 20px;
        justify-content: center !important;
    }
    .my-list-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: space-between;
        column-gap: 30px;
        @media (max-width: 1200px) {
            column-gap: 28px;
        }
    }
    .alert-info {
        margin-top: 10px;
    }
}
