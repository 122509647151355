@import '../../../../common/styles/variables/colors';

.heart-icon {
    margin-right: 5px;

    &.green {
        color: $color-green;
    }

    &.icon-only {
        margin-right: 0;
    }
}
