@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/bootstrap/variables';

.drop-zone {
    border-radius: $border-radius;
    background-color: $color-grey-light-transparent;
    text-align: center;
    padding: 2rem 0;

    .bold {
        font-weight: bold;
    }
}
