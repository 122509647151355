@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/mixins/shadow';

.filter-checkboxes {
    margin: 0;
    padding: 0;

    .element {
        @include wrap-text();
        label {
            cursor: pointer;
            &:hover {
                color: $color-green;
            }
        }
        input[type='checkbox'] {
            appearance: none !important;

            background-color: $color-white;
            position: relative;
            margin-right: 0.8rem;
            margin-left: 0;
            margin-top: 0;
            top: 0.1rem;
            border: 1px solid $color-grey-lighter;

            &:checked {
                background-color: $color-green;
                border: none;
                @include common-box-shadow();
            }

            &:checked:after {
                color: $color-white;
                content: '';
                left: 5px;
                top: 2px;
                width: 6px;
                height: 10px;
                border: solid $color-white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        .checkbox-col {
            $width: 14px;

            width: $width;
            max-width: $width;
        }

        .min-max {
            height: 30px;
        }
    }
}
