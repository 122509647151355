@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/variables/colors';
@import '../../../../../../common/styles/variables/fonts';
@import '../../../../../../common/styles/mixins/shadow';
@import '~bootstrap/scss/mixins/breakpoints';

.testimonial-box {
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;

    .opinion {
        @include common-box-shadow();

        align-items: center;
        background-color: $color-white;
        border-radius: $border-radius;
        color: $color-grey-darker;
        display: flex;
        flex-grow: 1;
        padding: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width;
        }
    }

    .triangle {
        @include common-box-shadow();
        $size: 12px;

        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size * 1.25 solid $color-white;
        height: 0;
        margin-left: 1.25rem;
        width: 0;
    }

    .client,
    .location {
        font-weight: $font-weight-between-normal-and-bold;
        margin-left: 0.75rem;
    }

    .client {
    }

    .location {
        color: $color-green;
    }
}
