@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';

.main {
    background-color: $color-white;

    .article {
    }

    .sidebar {
        margin-top: $grid-gutter-width;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

.recommended {
    background-color: $color-grey-almost-white;
    padding: {
        bottom: 3rem;
        top: 1.5rem;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 4rem;
    }

    .title {
        text-align: center;
    }
}
