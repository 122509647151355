@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/mixins/shadow';

$breakpoint: lg;

%inline-value {
    margin-left: 5px;
}

%small-line-height {
    line-height: 1.25;
}

.buy-now-box {
    margin-top: $grid-gutter-width;

    .wrapper {
        @include common-box-shadow();

        background-color: $color-white;
        text-align: center;

        .price-and-size {
            padding: {
                bottom: $grid-gutter-width * 0.5;
                top: $grid-gutter-width;
            }

            .price {
                .value {
                    @extend %small-line-height;

                    color: $color-green;
                    font: {
                        size: $h1-font-size-xl;
                        weight: $font-weight-boldest;
                    }

                    @include media-breakpoint-up(sm) {
                        font-size: $font-size-xl;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: $h1-font-size-xl;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: $font-size-xl + 0.25rem;
                    }
                }

                .below-price {
                    color: $color-grey-dark;
                }
            }

            .size {
                margin-top: 10px;

                .value {
                    @extend %small-line-height;

                    font: {
                        size: $h34-font-size;
                        weight: $font-weight-bold;
                    }

                    @include media-breakpoint-up($breakpoint) {
                        font-size: $h2-font-size;
                    }
                }

                .below-size {
                    color: $color-grey-dark;
                    font-size: $h56-font-size;
                }
            }
        }

        .short-information {
            background-color: $color-grey-almost-white;
            padding: $grid-gutter-width * 0.5;

            .name,
            .value {
                font-size: $h56-font-size;
                line-height: 2;

                @include media-breakpoint-up($breakpoint) {
                    font-size: $font-size-base;
                }
            }

            .name {
                font-weight: $font-weight-light;
            }

            .value {
                @include wrap-text();
                @extend %inline-value;

                font-weight: $font-weight-bold;
            }

            .listing-id {
            }

            .financing-term {
            }

            .monthly-payment {
            }
        }

        .processing-fee {
            padding: $grid-gutter-width * 0.5;

            .name {
                font-weight: $font-weight-light;
            }

            .value {
                @extend %inline-value;

                color: $color-red-dark;
                font-weight: $font-weight-bold;
            }
        }
    }

    .buy-button {
        .button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-size: $h5-font-size;
            width: 100%;
        }
    }

    .buy-links {
        display: flex;
        justify-content: space-between;
        font-size: $h56-font-size;
        padding-top: 12px;
    }
}
