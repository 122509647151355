@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/bootstrap/custom';

.description {
    padding-top: $grid-gutter-width;

    img {
        max-width: 100%;
    }

    .title-wrapper {
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    .text {
        font-size: $h56-font-size;
        line-height: 1.75;
    }
}
