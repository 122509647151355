@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/variables/fonts';
@import '~bootstrap/scss/mixins/breakpoints';

$width-small-reg: 60rem;

.modal-wrapper {
    &.registration-modal {
        @include media-breakpoint-up(xl) {
            width: $width-small-reg;
        }

        .modal-body {
            .container {
                max-width: 100%;
                padding: 0;
                margin: 0 auto;

                .row {
                    width: 100%;
                    margin: 0;

                    .col-lg-6 {
                        padding: 2.125rem;
                    }
                }
            }

            .form {
                .col {
                    padding: 0;
                }
            }

            .promo {
                text-align: left;
                padding: 1rem !important;

                h4 {
                    margin: 1.25rem 0 1.8rem;
                    font-weight: $font-weight-bold;
                    text-align: center;

                    @include media-breakpoint-up(lg) {
                        text-align: left;
                    }
                }

                .one-promo {
                    margin-bottom: 3rem;

                    .icon {
                        width: 4.5rem;
                        height: 4.5rem;
                        background-color: $color-white;
                        border-radius: 100%;
                        font-size: 2.25rem;
                        color: $color-green;
                        text-align: center;
                        float: left;
                        margin-right: 1.25rem;

                        i {
                            position: relative;
                            top: 40% !important;
                            transform: translateY(-50%) !important;
                        }
                    }

                    .text {
                        margin-left: 5.75rem;

                        h5 {
                            font-weight: $font-weight-between-normal-and-bold;
                        }

                        div {
                            font-size: 0.9rem;
                        }
                    }
                }

                & :nth-child(4) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
