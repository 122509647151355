@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';

.search-wrapper {
    width: 100%;

    @include media-breakpoint-up(lg) {
    }

    $height: 3rem;

    .search-group {
        @include media-breakpoint-up(lg) {
        }
        .input-group-prepend {
            background-color: #ffffff;
        }

        .search-icon {
            color: $color-grey-light;
            border: 1px solid $color-grey-ultra-light;
            border-right: none;
            background-color: transparent;
        }

        .search-input {
            display: inline;
            height: $height;
            border-radius: 0;
            border-left: none;
            font-size: $h56-font-size;
            padding-left: 2px;

            @include media-breakpoint-up(lg) {
                width: 60%;
                display: inline;
            }

            &:focus {
                box-shadow: none;
                border: 1px solid $color-grey-ultra-light;
                border-left: none;
            }
        }
    }

    .search-button {
        border-radius: 0 0 0.2rem 0.2rem;
        height: $height;
        width: 100% !important;

        &:focus {
            box-shadow: none;
        }

        @include media-breakpoint-up(lg) {
            border-radius: 0 $border-radius $border-radius 0;
            width: 8rem !important;
        }
    }
}
