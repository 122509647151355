@import '../../../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$breakpoint: md;

.testimonials-section {
    .testimonial-wrapper {
        display: flex;
        margin-top: $grid-gutter-width * 0.5;

        &:first-of-type {
            margin-top: 0;
        }

        @include media-breakpoint-up($breakpoint) {
            margin-top: 0;
        }

        &:nth-child(-n + 3) {
            @include media-breakpoint-up($breakpoint) {
                margin-top: 0; // No margin for the first 3 elements
            }
        }

        @include media-breakpoint-up($breakpoint) {
            margin-top: $grid-gutter-width;
        }
    }
}
