@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/fonts';

.title {
    $margin: 1.5rem;

    font-weight: $font-weight-between-normal-and-bold;
    margin: {
        bottom: $margin;
        top: $margin;
    }

    @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
    }
}
