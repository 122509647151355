@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/bootstrap/custom';

$breakpoint: lg;

.categories {
    margin-top: 10px;

    .category {
        display: inline-block;

        &:last-of-type {
            .separator {
                display: none;
            }
        }

        .link {
            @extend %light-letter-spacing;

            color: $color-grey-darker;
            font-size: $h6-font-size;
            text-transform: uppercase;

            @include media-breakpoint-up($breakpoint) {
                font-size: $h56-font-size;
            }
        }

        .separator {
            font-size: $h56-font-size;
            margin-right: 10px;

            @include media-breakpoint-up($breakpoint) {
                font-size: $font-size-base;
            }
        }
    }
}
