@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';

.floating-btn {
    position: fixed;
    float: right;
    width: 4rem;
    height: 4rem;
    right: 1rem;
    bottom: 1rem;
    z-index: 1;

    padding-top: 0.375rem;
    background-color: $primary;
    border-radius: 100%;
    cursor: pointer;

    font-size: $h1-font-size-xl;
    text-align: center;
    color: white;

    &:hover {
        box-shadow: 0 0 0.3rem $old-color-grey-dark-lighter;
    }
}

.faq-container {
    .table-contents {
        list-style: circle;
        margin-bottom: 2rem;

        a {
            font-weight: $font-weight-bold;
        }
    }

    .topic {
        &:last-of-type {
            .answer {
                margin-bottom: 0;
            }
        }

        .header {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
        }
    }
}
