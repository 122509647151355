.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    $margin-between: 0.1rem;

    .filters {
        display: flex;
        flex-direction: row;

        .form-group {
            margin: 0 $margin-between;
        }

        .button {
            display: flex;
            align-items: center;
        }
    }

    .exports {
        display: flex;
        align-items: center;

        span,
        button {
            margin: 0 $margin-between;
        }
    }

    &.adding-header {
        justify-content: flex-end;
    }
}
