@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../../common/styles/mixins/transition';

$breakpoint: lg;

.images {
    $margin: 20px;

    display: none;
    margin-top: $margin;

    @include media-breakpoint-up($breakpoint) {
        display: block;
        overflow: hidden;
    }

    .wrapper {
        $scrollbar-height: 15px;

        // Manipulation required to hide scrollbar
        &.scrolling {
            bottom: -$scrollbar-height;
            margin-top: -$scrollbar-height;
            position: relative;
        }

        display: flex;
        overflow-x: auto;

        &:not(.scrolling) {
            justify-content: center;
        }

        .image {
            @include common-transition();
            border-radius: $border-radius;

            & + .image {
                margin-left: $margin;
            }

            &.active {
                background-color: rgba($color-green, 0.9);

                img {
                    opacity: 0.3;
                }
            }

            img {
                border-radius: $border-radius;
                cursor: pointer;
                width: 110px;
            }
        }
    }
}
