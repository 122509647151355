.header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.editing-modal {
    width: auto;
    max-width: 600px;

    .modal-body {
        padding: 15px;
        text-align: left;
    }
}
.react-bootstrap-table table {
    a {
        text-decoration: none;
        border-bottom: none;
    }
}