@import '../../../../common/styles/variables/colors';

.pagination {
    margin: 40px 0 0 0;

    .page-item {
        $size: 2.25rem;

        width: $size;
        height: $size;

        & + .page-item {
            margin-left: 0.8rem;
        }

        .page-link {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            border-radius: 0.25rem;
            background-color: $color-white;
            color: $old-color-grey-dark-transparent;
            border-color: $color-grey-ultra-light;

            &.active,
            &:hover {
                background-color: $color-green;
                color: $color-white;
                border-color: transparent;

                i.arrow {
                    border: solid $color-white;
                    border-width: 0 0.0625rem 0.0625rem 0;
                }
            }

            &.arrow-container {
                i {
                    border: solid $old-color-grey-dark-transparent;
                    border-width: 0 0.0625rem 0.0625rem 0;
                    display: inline-block;
                    padding: 0.25rem;
                    margin-bottom: 0.05rem;

                    &.right {
                        transform: rotate(-45deg);
                        margin-right: 0.25rem;
                    }

                    &.left {
                        transform: rotate(135deg);
                        margin-left: 0.25rem;
                    }
                }
            }
        }
    }
}
