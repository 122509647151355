@import '~meritoo-common-frontend/src/scss/mixins';
@import '~bootstrap/scss/mixins/hover';
@import '../../../../common/styles/bootstrap/custom';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';

.list-of-links {
    @include reset-all-margins();

    .list-item {
        list-style: none;

        &:before {
            color: $color-green;
            content: '\2023 \0020';
        }

        .link {
            @include wrap-text();
            @extend %link-without-underline;

            color: inherit;
            font-size: $font-size-sm;
            line-height: 2rem;
            padding-left: 0.25rem;

            @include hover() {
                border-bottom: 1px solid $color-green;
                transition: none;
            }
        }
    }
}
