@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../../common/styles/variables/fonts';

$breakpoint: lg;

%navigation-arrow {
    background-color: $color-grey-more-light-transparent;
    border-radius: $border-radius;
    color: $color-white;
    cursor: pointer;
    font-size: $h45-font-size;
    padding: 5px 13px;
    position: relative;
    z-index: 1;

    &.without-arrows {
        display: none;
    }
}

.main-image {
    $arrow-margin: 20px;
    $arrow-width: 37px;

    display: none;

    @include media-breakpoint-up($breakpoint) {
        align-items: center;
        display: flex;
    }

    img {
        border-radius: $border-radius;
        max-width: 100%;

        &.with-arrows {
            left: $arrow-width * (-1);
            position: relative;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
        &.video-with-arrows {
            left: $arrow-width * (-1);
            position: relative;
        }
    }

    .left-arrow {
        @extend %navigation-arrow;
        left: $arrow-margin;
    }

    .right-arrow {
        @extend %navigation-arrow;
        right: $arrow-width * 2 + $arrow-margin;
    }
}
