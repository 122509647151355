@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../common/styles/variables/colors';
@import '../../../common/styles/mixins/shadow';
@import '../../../common/styles/mixins/checkboxes';

.acre-bootstrap-table {
    @include systemic-checkboxes;

    td {
        @include wrap-text();
    }

    input[type='checkbox'] {
        background-color: $color-white;
        position: relative;
        margin-right: 0.8rem;
        border: 1px solid $color-grey-lighter;

        &:checked {
            background-color: $color-green;
            border: none;
            @include common-box-shadow();
        }

        &:checked:after {
            color: $color-white;
            position: absolute;
            content: '';
            left: 5px;
            top: 2px;
            width: 6px;
            height: 10px;
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}

.react-bootstrap-table {
    table {
        table-layout: auto; // Required to stop wrapping content of table in each row

        .actions {
            text-align: center;

            .action {
                cursor: pointer;

                & + .action {
                    margin-left: 10px;
                }
            }
        }
    }
}

.react-bootstrap-table-pagination-list {
    .page-item {
        &.active,
        &:hover {
            .page-link {
                background-color: $color-green;
                border-color: transparent;
                color: $color-white;
            }
        }
    }
}
