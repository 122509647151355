@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/fonts';

.map {
    $breakpoint: md;

    .map-container {
        height: 300px;

        @include media-breakpoint-up($breakpoint) {
            height: 500px;
        }
    }

    .open-google-maps {
        float: left;
        font-size: $h56-font-size;
        margin-top: $grid-gutter-width * 0.5;

        @include media-breakpoint-up($breakpoint) {
            font-size: $font-size-base;
        }
    }
}
