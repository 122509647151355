@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../../common/styles/variables/fonts';
@import '../../../../../../../../common/styles/bootstrap/variables';

.contact-form {
    $padding: $grid-gutter-width * 0.5;

    padding: {
        left: $padding;
        right: $padding;
        bottom: $padding;
        top: $grid-gutter-width;
    }

    .text-field {
        height: 40px;
        text-align: center;

        @include media-breakpoint-up(md) {
            height: 45px;
        }

        @include media-breakpoint-up(lg) {
            height: auto;
        }

        &::placeholder {
            font-size: $h56-font-size;
            line-height: 1.6;

            @include media-breakpoint-up(md) {
                line-height: 1.8;
            }

            @include media-breakpoint-up(lg) {
                line-height: 2;
            }
        }

        &.message {
            min-height: 125px;
        }
    }

    .submit-button-wrapper {
        display: flex;
        justify-content: center;

        .submit-button {
            font-size: $h56-font-size;
            padding-bottom: $btn-padding-y-lg;
            padding-top: $btn-padding-y-lg;
            width: 100%;

            @include media-breakpoint-up(xs) {
                width: 260px;
            }

            @include media-breakpoint-up(md) {
                width: 200px;
            }
        }
    }
}
