@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/fonts';

.contact-form {
    padding-bottom: $grid-gutter-width;
    width: 100%;

    .text-field {
        font-size: $h56-font-size;
        height: 2.25rem;
    }

    textarea {
        min-height: 125px;
    }

    .submit-button {
        font-size: $h56-font-size;
        width: 100%;
    }
}
