@import '../../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.auth-button {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
.logout-button {
    margin-left: 10px;
}
