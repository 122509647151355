@import '../../../../../../common/styles/variables/colors';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/bootstrap/custom';
@import '../../../../../../common/styles/mixins/shadow';
@import '~bootstrap/scss/mixins/breakpoints';

.popular-category {
    $padding: $grid-gutter-width * 0.75;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & + .popular-category {
        padding-top: $padding;

        @include media-breakpoint-up(lg) {
            padding-top: $grid-gutter-width;
        }

        .title {
            padding-top: $padding;
        }
    }

    $breakpoints: (
        2: sm,
        3: xl,
    );

    @each $index, $breakpoint in $breakpoints {
        &:nth-child(-n + #{$index}) {
            @include media-breakpoint-up($breakpoint) {
                padding-top: 0; // No padding for the first elements (2 or 3 - depends on breakpoint)

                .image {
                    .title {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .image {
        @extend %link-without-underline;

        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &:hover {
            img {
                filter: brightness(0.4);
            }
        }

        img {
            @include common-box-shadow();

            border-radius: 0.25rem;
            filter: brightness(0.6);
            height: 100px;
            object-fit: cover;
            transition: filter 1s ease-out;
            width: 100%;

            @include media-breakpoint-up(md) {
                height: auto;
                object-fit: initial;
            }
        }

        .title {
            color: $color-white;
            font-weight: $font-weight-between-normal-and-bold;
            left: 50%;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            @include media-breakpoint-up(lg) {
                font-size: $font-size-lg;
            }
        }
    }
}
