@import '../../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.navbar-nav {
    $vertical-space-small: 1rem;
    $vertical-space-standard: $vertical-space-small * 1.5;
    height: 88px;
    padding-bottom: 0;
    padding-top: 0;
    .vsplit {
        display: none;
        height: 32px;
        width: 1px;
        background-color: #e1e1e1;
        margin-left: 10px;
        margin-right: 28px;
        margin-top: 28px;
    }
    @include media-breakpoint-up(lg) {
        .vsplit { display: block; }
    }
    .nav-item {
        .nav-link {
            font-size: $font-size-lg;
            margin-bottom: -14px;
            text-align: center;
            padding-right: 2vw;
            padding-left: 2vw;
            display: block;
            height: 76px;
            line-height: 48px;
            border-bottom: 3px solid transparent;
            @include media-breakpoint-up(md) {
                font-size: $font-size-base;
            }
            @include media-breakpoint-up(lg) {
            }
        }
    }
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: black;
    border-bottom: 3px solid #71b893;
}