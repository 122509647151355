@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/functions/background';

.featured-properties-section {
    padding-bottom: 0 !important;
    .featured-property-wrapper {
        display: flex;
        margin-bottom: 12px;
        & + .featured-property-wrapper {
            margin-top: $grid-gutter-width * 0.5;
        }

        &:first-of-type {
            .featured-property {
                margin-top: 0;
            }
        }

        $breakpoints: (
            2: sm,
            3: lg,
            4: xl,
        );

        @each $index, $breakpoint in $breakpoints {
            &:nth-child(-n + #{$index}) {
                @include media-breakpoint-up($breakpoint) {
                    margin-top: 0; // No margin for the first elements (2, 3 or 4 - depends on breakpoint)

                    .featured-property {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
