@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';

%margin-top {
    margin-top: $grid-gutter-width * 0.5;
}

.image {
    @extend %margin-top;

    border-radius: $border-radius;
    width: 100%;
}

.content {
    @extend %margin-top;

    line-height: 1.8;
    white-space: normal;

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
    }
}

.share {
    margin-top: $grid-gutter-width;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
    }
}
