@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/bootstrap/custom';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/hover';

.title {
    font-weight: $font-weight-bold;
    text-align: center;

    @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
        text-align: right;
    }
}

.icons {
    align-items: center;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
    }

    a {
        @extend %link-without-underline;
        $size: 40px;

        align-items: center;
        background-color: $color-grey-light-transparent;
        border-radius: $border-radius;
        color: $color-white;
        display: flex;
        height: $size;
        justify-content: center;
        padding: 15px;
        width: $size;

        @include media-breakpoint-up(lg) {
            $size: 55px;

            height: $size;
            width: $size;
        }

        @include hover() {
            background-color: rgba(0, 0, 0, 0.4);
            color: #7bf9b7;
        }

        & + a {
            margin-left: 10px;

            @include media-breakpoint-up(lg) {
                margin-left: 15px;
            }
        }

        i {
            @include media-breakpoint-up(lg) {
                font-size: $h3-font-size;
            }
        }
    }
}
