@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../../../common/styles/bootstrap/variables';

$zindex-notification: $zindex-tooltip + 10;

.notification-wrapper {
    position: fixed;
    right: 0;
    top: 0;
    z-index: $zindex-notification;
}
