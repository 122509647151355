@import '../../../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.see-all-button-container {
    padding-top: $grid-gutter-width;

    @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width * 2;
    }
}
