@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/bootstrap/variables';

.navbar-nav {
    $width-small: 200px;
    $width-standard: $width-small + 30px;
    $margin-left: 10px;
    .nav-item {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }
        .btn {
            padding-top: 9px;
            padding-bottom: 9px;
            white-space: nowrap;
            @include media-breakpoint-up(lg) {
                padding-left: 26px;
                padding-right: 26px;
                margin-right: 10px;
            }
            @include media-breakpoint-up(xl) {
                padding-left: 26px;
                padding-right: 26px;
                margin-right: 10px;
            }
        }
        &.sign-up-button,
        &.login-button {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }

            .btn {
                font-size: $font-size-sm;
                width: $width-small;

                @include media-breakpoint-up(xs) {
                    width: $width-standard;
                }

                @include media-breakpoint-up(md) {
                    display: flex;
                    margin-left: $margin-left;
                    width: auto;
                }
            }
        }

        &.login-button {
            margin-top: 10px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
    .auth-button {
        .btn {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
