@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../common/styles/variables/colors';
@import '../../../../../../common/styles/variables/fonts';
@import '../../../../../../common/styles/bootstrap/variables';
@import '../../../../../../common/styles/mixins/shadow';

%padding {
    padding: $grid-gutter-width * 0.5;
}

.contact-box {
    margin-top: $grid-gutter-width * 1.5;

    .wrapper {
        @include common-box-shadow();
        $padding: $grid-gutter-width * 0.5;

        background-color: $color-white;
        padding: {
            bottom: $padding;
            top: $padding;
        }

        .seller {
            @extend %padding;

            align-items: center;
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            padding-top: 12px;

            .logo {
            }

            .name {
                font-size: $h56-font-size;
                padding-top: 5px;
                text-align: center;

                @include media-breakpoint-up(lg) {
                    font-size: $font-size-base;
                    padding-top: 8px;
                }
            }
        }

        .phone {
            padding-top: $grid-gutter-width;

            .separator {
                align-items: center;
                display: flex;

                .title {
                    $margin: $grid-gutter-width * 0.5;

                    font-size: $h56-font-size;
                    margin: {
                        left: $margin;
                        right: $margin;
                    }
                }

                .horizontal-line {
                    background-color: $color-grey-ultra-light;
                    flex-grow: 2;
                    height: 1px;
                }
            }

            .phone-number {
                @extend %padding;

                color: $color-green;
                font: {
                    size: $h2-font-size;
                    weight: $font-weight-boldest;
                }
                padding: {
                    bottom: $grid-gutter-width * 0.75;
                    top: $grid-gutter-width * 0.25;
                }
                text-align: center;
            }
        }
    }
}
