@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../common/styles/variables/colors';
@import '../../../common/styles/bootstrap/variables';

.blog-wrapper {
    padding-top: 2.8125rem;
    background-color: $color-white;

    .acre-header {
        h4 {
            margin-bottom: 0;
        }
    }

    .slider-part {
        padding-bottom: 80px;
    }

    .second-part {
        background-color: $color-grey-almost-white;
        padding: 40px 0;
    }
}
