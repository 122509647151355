@import '../../../../../common/styles/variables/colors';
@import '../../../../../common/styles/bootstrap/variables';
@import '../../../../../common/styles/mixins/checkboxes';

.form-wrapper {
    .property-form {
        .form-buttons {
            position: sticky;
            z-index: 100;
            top: 2rem;
            margin-top: 2rem;
        }

        .form-title {
            display: block;
            width: 100%;
        }

        .desc-editor-wrapper {
            border: 1px solid $color-grey-light;

            .editor-field {
                padding: 0 10px;
                min-height: 200px;
            }
        }

        .textarea-field {
            height: 355px;
        }

        .switch {
            label {
                cursor: pointer;
            }
        }

        .category-form-check-label {
            margin-left: 25px;
            display: block;
            margin-bottom: 20px;
        }
        .property-form-check-label {
            margin-right: 25px;
            margin-bottom: 20px;
        }

        @include systemic-checkboxes;
    }

    .date-picker-calendar {
        position: absolute;
        z-index: 100;
        background-color: $color-white;
    }

    .tip {
        color: $color-grey-light;
        font-size: $font-size-sm;
        text-align: center;
    }

    .uploaded-images {
        margin-top: 0.5rem;

        .image {
            border-radius: $border-radius-lg;
            border: 1px solid $color-green;
            margin-bottom: 0.5rem;
            min-height: 22rem;

            img {
                width: 100%;
            }

            .image-data {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                color: $color-green;
                padding-bottom: 0.75rem;

                .primary {
                    margin-top: 0.5rem;
                    margin-left: 0.5rem;
                }

                .icons {
                    margin-left: auto;
                    margin-right: 0.5rem;

                    i {
                        cursor: pointer;
                        margin: 0.1rem;
                    }
                }
            }
        }
    }
    input[type='checkbox'] {
        margin-bottom: -2px;
    }
}
