@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.newsletter-section {
    background: url('../images/social-bg.jpg') no-repeat center;
    background-size: cover;
    color: $color-white;

    @include media-breakpoint-up(lg) {
        padding-top: 4rem;
    }

    .wrapper {
        @include media-breakpoint-up(lg) {
            justify-content: center;
        }

        .social-media-box {
            margin-top: 1.5rem;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
    }
}
