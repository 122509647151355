@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../common/styles/bootstrap/variables';

$breakpoint-no-image: md;

.no-image {
    display: none;

    @include media-breakpoint-up($breakpoint-no-image) {
        display: block;
        width: 100%;
    }

    img {
        @include media-breakpoint-up($breakpoint-no-image) {
            width: 100%;
        }
    }
}
