@import '~bootstrap/scss/mixins/breakpoints';
@import '~meritoo-common-frontend/src/scss/mixins';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/bootstrap/custom';

$breakpoint: lg;

%go-to-map-link {
    cursor: pointer;
    font-size: $font-size-sm;
}

.top {
    .title {
        align-items: center;
        display: flex;

        .property-name {
            $margin: 0.4rem;

            margin: {
                bottom: $margin;
                top: $margin;
            }
        }

        .save {
            display: none;

            @include media-breakpoint-up($breakpoint) {
                display: block;
                margin-left: $grid-gutter-width;
            }
        }
    }

    .location-with-icon {
        display: flex;

        .location {
            font-size: $h56-font-size;
            line-height: 1.3;
            margin-left: 5px;

            @include media-breakpoint-up($breakpoint) {
                align-items: center;
                display: flex;
                font-size: $h45-font-size;
            }

            .address {
                @include wrap-text();
            }

            .go-to-map {
                @extend %go-to-map-link;
                display: none;

                @include media-breakpoint-up($breakpoint) {
                    display: block;
                    margin-left: $grid-gutter-width;
                    white-space: nowrap;
                }
            }

            .options {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 0.25rem;

                @include media-breakpoint-up($breakpoint) {
                    display: none;
                }

                .save {
                }

                .go-to-map {
                    @extend %go-to-map-link;
                    display: block;

                    @include media-breakpoint-up($breakpoint) {
                        display: none;
                    }
                }
            }
        }

        .pointer-icon {
            color: $color-grey-light;
            font-size: $font-size-lg;
            margin-right: 5px;
        }
    }
}
