@import '../variables/colors';
@import '../mixins/checkboxes';

.form-control {
    &::placeholder {
        transform-origin: 0 50%;
    }
}

@include fancy-checkboxes;
