@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/variables/fonts';

.date-container {
    align-items: center;
    display: flex;
    font-size: $font-size-sm;
    font-weight: $font-weight-between-normal-and-bold;

    @include media-breakpoint-up(md) {
        font-size: $font-size-base;
    }

    .date {
        line-height: 1;
        margin-left: 10px;
    }

    .icon {
        color: $color-grey-light;
    }
}
