@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700&display=swap');
@import '../variables/colors';

// Grid breakpoints
//
$grid-breakpoints: (
    xxs: 0,
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

// Color system
//
$primary: $color-green;
$light: $color-white;
$secondary: $color-grey-lighter;

// Body
//
$body-bg: $color-grey-almost-white;
$body-color: $color-black;

// Links
//
$link-color: $color-black;
$link-decoration: none;
$link-hover-decoration: none;

// Buttons + Forms
//
$input-btn-padding-x: 1.75rem;
$input-btn-padding-y: 0.75rem;
$input-btn-padding-x-lg: 4rem;
$input-btn-padding-y-lg: 1rem;

// Buttons
//
$btn-font-weight: 600;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;

// Typography
//
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-weight-light: 200;
$font-weight-normal: 400 !default;
$font-weight-bold: 600;

$headings-font-weight: $font-weight-normal;
$headings-line-height: 2;

$h1-font-size: $font-size-base * 2; // 32px
$h2-font-size: $font-size-base * 1.75; // 28px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1; // 16px
$h6-font-size: $font-size-base * 0.75; // 12px

// Modals
//
// I have to reset background of the backdrop, because gradient cannot be set as value of background-color
$modal-backdrop-bg: transparent;
$modal-backdrop-opacity: 1;

// Navbar
//
$navbar-nav-link-padding-x: 0.9rem;
$navbar-toggler-padding-x: 0.5rem;
$navbar-light-color: $color-black;
$navbar-light-toggler-border-color: $color-grey-lighter;

// Cards
//
$card-border-color: $color-grey-ultra-light;
$card-border-width: 1px;
$card-cap-bg: transparent;
$card-spacer-x: 1rem;
$card-spacer-y: $card-spacer-x;

// Components
//
$border-radius: 0.2rem;
$border-radius-lg: 0.25rem;
$border-radius-sm: 0.15rem;

// Forms
//
$input-placeholder-color: $color-grey-dark;
$input-border-color: $color-grey-ultra-light;
$input-color: $body-color;
$input-padding-x: 0.75rem;

// Tables
//
$table-accent-bg: $color-grey-ultra-light;
$table-striped-order: even;
$table-cell-padding: 0.9rem;
$table-cell-padding-sm: 0.5rem;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
