.input-array-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        margin-left: 1rem;
    }
}

.icon {
    cursor: pointer;
}
