@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../common/styles/bootstrap/variables';
@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/variables/fonts';
@import '../../../../common/styles/mixins/shadow';

.searchtoolbar-wrapper {
    @include common-box-shadow();
    border-radius: 0.25rem;
    background-color: white;
    padding: 24px;
}
