@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$font-family-icons: 'FontAwesome', serif;
$font-triangle-decoration: 'Arial';

$font-size-xs: $font-size-base * 0.625; // 10px
$font-size-xl: $font-size-base * 2.5; // 40px
$font-size-xxl: $font-size-base * 3; // 48px
$font-size-huge: $font-size-base * 4; // 64px

$font-weight-between-normal-and-bold: 500;
$font-weight-boldest: bold;

$h1-font-size-xxl: $font-size-base * 2.375; // 38px
$h1-font-size-xl: $font-size-base * 2.25; // 36px
$h23-font-size: $font-size-base * 1.625; // 26px
$h34-font-size: $font-size-base * 1.375; // 22px
$h45-font-size: $font-size-base * 1.125; // 18px
$h56-font-size: $font-size-base * 0.875; // 14px
