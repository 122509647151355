@import '../../../../common/styles/variables/colors';
@import '../../../../common/styles/bootstrap/variables';
@import '~bootstrap/scss/mixins/breakpoints';

footer {
    background: url('../images/footer-bg.jpg') no-repeat center;
    background-size: cover;
    color: $color-white;
    padding-top: 1.5rem;
    @include media-breakpoint-up(xl) {
        padding-top: 2rem;
    }
    .copyright {
        $padding: 1.25rem;
        background-color: $color-grey-light-transparent;
        font-size: $h6-font-size;
        padding: {
            bottom: $padding;
            top: $padding;
        }
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin-top: 1rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 2rem;
        }
    }
    @media only screen and (max-width: 1200px) {
        a {
            span {
                display: none;
            }
        }
    }
}
