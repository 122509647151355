@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../common/styles/bootstrap/variables';
@import '../../../common/styles/bootstrap/custom';

.share {
    align-items: center;
    display: flex;

    @include media-breakpoint-up(lg) {
        order: 2;
    }

    .text {
        font-size: $font-size-sm;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: $font-size-base;
        }
    }

    .icons {
        display: flex;
        margin-left: $grid-gutter-width * 0.5;

        .icon {
            & + .icon {
                margin-left: $grid-gutter-width * 0.5;
            }

            i {
                border-radius: 50%;
                color: $color-white;
                font-size: $h5-font-size;
                padding: 10px;
            }

            &.facebook {
            }

            &.twitter {
            }

            &.envelope {
            }

            &.link {
                cursor: pointer;

                i {
                    background-color: $color-green;
                }
            }
        }
    }
}
